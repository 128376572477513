* {
  box-sizing: border-box !important;
}

::-webkit-scrollbar {
  width: 10px; /* 스크롤바의 너비 */
}

::-webkit-scrollbar-thumb {
  background: #f5f5f5; /* 스크롤바의 색상 */

  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(33, 122, 244, 0.1); /*스크롤바 뒷 배경 색상*/
}

#root {
  --background: #524fa1;
  --white: #ffffff;
  --yellow: #ffc700;
  --border: #d3cec4;
  --mint: #31b5b9;
  --light-mint: #88d8db;
  --light-purple: #7b78d5;
  --purple: #5949a2;
  --red: #fe5c00;
  width: 100%;
  font-size: 10px; /*1rem = 10px*/
}

body {
  margin: 0;
  padding: 0;
  height: 100%;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --font-size-xl: clamp(1rem, 7vw, 2.5rem); /*35px*/
  --font-size-lg: clamp(1rem, 6vw, 2.5rem); /*30px*/
  --font-size-ml: clamp(1rem, 5vw, 2rem); /*25px*/
  --font-size-md: clamp(1.5rem, 4vw, 1.75rem); /*20px*/
  --font-size-ms: clamp(1.2rem, 3vw, 1.5rem); /*15px*/
  --font-size-sm: clamp(1rem, 1vw, 1.5rem); /*10px*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
